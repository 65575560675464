<template>
  <div fluid>
    <!-- Table Top -->
    <b-card>
      <b-row>
        <b-col>
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form
              v-if="liveData !== null"
              ref="myForm"
              class="p-2"
              @submit.prevent="handleSubmit(editLive)"
            >
              <fieldset :disabled="isDatePassed">
                <b-row>
                  <b-col md="6">
                    <inpo-select
                      :inpo-id.sync="liveData.entity_id.id"
                      :is-disabled="isDatePassed"
                    />
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live Name"
                      rules="required"
                    >
                      <b-form-group
                        label="Live Name"
                        label-for="live_name"
                      >
                        <b-form-input
                          id="live_name"
                          v-model="liveData.activity_name"
                          placeholder="Live Name"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live Description"
                      rules="required"
                    >
                      <b-form-group
                        label="Live Description"
                        label-for="live_des"
                      >
                        <b-form-input
                          id="live_des"
                          v-model="liveData.activity_description"
                          placeholder="Live Description"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live Link"
                      rules="required|youtube-url"
                    >
                      <b-form-group
                        label="Live Link"
                        label-for="live_des"
                      >
                        <b-form-input
                          id="live_des"
                          v-model="liveData.session_link"
                          placeholder="Live Link"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live Start"
                      rules="required"
                    >
                      <b-form-group
                        label="Live Start"
                        label-for="live_des"
                      >
                        <flat-pickr
                          v-model="liveData.session_start"
                          class="form-control"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: !isDatePassed? 'today': '' }"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live End"
                      rules="required"
                    >
                      <b-form-group
                        label="Live End"
                        label-for="live_des"
                      >
                        <flat-pickr
                          v-model="liveData.session_end"
                          class="form-control"
                          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minDate: liveData.session_start }"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="To Be Raised"
                      rules="required"
                    >
                      <b-form-group
                        label="To Be Raised"
                        label-for="to_be_raised"
                      >
                        <b-form-input
                          id="to_be_raised"
                          v-model="liveData.session_to_be_raised"
                          type="number"
                          placeholder="To Be Raised"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </fieldset>
              <b-row>
                <b-button
                  v-if="!isDatePassed"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Save
                </b-button>
                <back />
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'

import InpoSelect from '@/common/components/LiveManagement/InpoLinkedWithStripeSelect.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import StartDatePassedStatus from '@/common/compositions/common/datePassed'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditLive',
  components: {
    flatPickr,
    InpoSelect,
    Back,
  },
  setup() {
    const { isDatePassed, checkIsDatePassed } = StartDatePassedStatus()
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const { setFormData, formData } = handleFormData()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
      setFormData,
      formData,
      isDatePassed,
      checkIsDatePassed,
    }
  },
  data() {
    return {
      liveData: null,
    }
  },
  mounted() {
    this.getLive()
  },
  methods: {
    getLive() {
      this.$activities.get(`internalops/live/${this.$router.currentRoute.params.id}`).then(res => {
        this.liveData = res.data.data
        this.checkIsDatePassed(this.liveData.session_start)
      })
    },
    editLive() {
      this.setEntityID()
      this.setFormData(this.liveData)
      this.$activities.post(`internalops/live/${this.$router.currentRoute.params.id}?_method=PUT`, this.formData).then(() => {
        this.successfulOperationAlert('Live is Updated successfully')
        this.$router.push({ name: 'live-management-list' })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    setEntityID() { // should be removed when api fix this point
      if (typeof this.liveData.entity_id === 'object') this.liveData.entity_id = this.liveData.entity_id.id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
